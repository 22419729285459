import api from './BaseApi';
import { UpdateFolderModel, WorkspaceFolderModel } from './models/WorkspaceObjectModel';

/**
 * Api for create and update folder
 *
 * @see WorkspaceApi if you like to read/move/remove folders
 */
export default class FolderApi {
  static async retrieve(folderId: string): Promise<WorkspaceFolderModel> {
    const result = await api.get(`workspace/folders/${folderId}`);
    return result.data;
  }

  static async create(name: string, parentFolderId: string): Promise<WorkspaceFolderModel> {
    const body = {
      name,
      parentId: parentFolderId,
    };
    const result = await api.post('workspace/folders', body);
    return result.data;
  }

  static async update(objectId: string, updatePayload: UpdateFolderModel): Promise<void> {
    return api.post(`workspace/folders/${objectId}`, updatePayload);
  }
}
