import api from '@/apis/BaseApi';
import {
  Member, MemberGroupModel, MemberGroupPreviewModel, UpdateMemberGroupPayload, UpdateMemberPayload,
} from './models/MemberGroupModel';

export default class MemberGroupApi {
  /**
   * Create a MemberGroup with given name for a Workspace
   *
   * @param workspaceId the id of the workspace to create memeber group
   * @param name the name for the member group, should pass regex: ^[^\s_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:\[\]]{2,}$
   */
  static async create(parentFolderId: string, name: string): Promise<MemberGroupPreviewModel> {
    const body = {
      name,
      parentId: parentFolderId,
    };

    const result = await api.post('workspace/member-groups', JSON.stringify(body));
    return result.data.memberGroup;
  }

  /**
   * Get the detailed information of a member group
   *
   * @param workspaceId
   * @param memberGroupId
   */
  static async getMemberGroup(memberGroupId: string): Promise<MemberGroupModel> {
    const result = await api.get(`workspace/member-groups/${memberGroupId}`);

    // map member object
    const { members } = result.data.memberGroup;
    for (let i = 0; i < members.length; i += 1) {
      const {
        uuid, name, businessDefinedId, confirmed,
      } = members[i];
      members[i] = new Member(uuid, name, businessDefinedId, confirmed);
    }

    return result.data.memberGroup;
  }

  /**
   * Update a member group
   *
   * Usage:
   * use UpdateMemberPayload.constructor to build payload,
   * if there are some field you do not intend to udpate,
   * just pass `null` as value for fields in the constructor,
   * e.g. new UpdateMemberGroupPayload(null).
   *
   *
   * @param workspaceId
   * @param memberGroupId
   * @param updatePayload
   */
  static async update(
    memberGroupId: string,
    updatePayload: UpdateMemberGroupPayload,
  ): Promise<MemberGroupPreviewModel> {
    const body = {
      ...updatePayload,
    };

    const result = await api.put(`workspace/member-groups/${memberGroupId}`, body);
    return result.data.memberGroup;
  }

  /**
   * Delete a member in a member group in a workspace
   *
   * @param workspaceId
   * @param memberGroupId
   * @param memberId
   */
  static async deleteMemberInMemberGroup(memberGroupId: string, memberId: string): Promise<void> {
    const result = await api.delete(`workspace/member-groups/${memberGroupId}/members/${memberId}`);
    return result.data;
  }

  /**
   * Update a member state in a member group in a workspace
   *
   * Usage:
   * use UpdateMemberPayload.constructor to build payload,
   * if there are some field you do not intend to udpate,
   * just pass `null` as value for fields in the constructor,
   * e.g. new UpdateMemberPayload(null).
   *
   *
   * @param workspaceId
   * @param memberGroupId
   * @param memberId
   */
  static async updateMemberInMemberGroup(
    memberGroupId: string,
    memberId: string,
    updatePayload: UpdateMemberPayload,
  ): Promise<void> {
    const body = {
      ...updatePayload,
    };

    const result = await api.put(`workspace/member-groups/${memberGroupId}/members/${memberId}`, body);
    return result.data;
  }
}
