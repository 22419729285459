





























import Vue from 'vue';
import MemberGroupApi from '@/apis/MemberGroupApi';
import { MemberGroupPreviewModel, UpdateMemberGroupPayload } from '@/apis/models/MemberGroupModel';
import {
  WorkspaceObjectModel, WorkspaceObjectType, translate,
} from '@/apis/models/WorkspaceObjectModel';

import FolderApi from '@/apis/FolderApi';

const MODAL_WIDTH = 656;
export default Vue.extend({
  data() {
    return {
      translate,
      modalName: 'update-workspace-object-name-modal',
      modalWidth: MODAL_WIDTH,
      remind: '',
      workspaceObjectName: '',
      workspaceObject: null as WorkspaceObjectModel | null,
    };
  },
  computed: {
    objectType(): string {
      if (this.workspaceObject) {
        return translate(this.workspaceObject.metadata.type);
      }
      return this.$t('未知').toString();
    },
  },
  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    beforeOpen(event: any) {
      this.remind = '';
      if (event.params.workspaceObject) {
        this.workspaceObject = event.params.workspaceObject as WorkspaceObjectModel;
        this.workspaceObjectName = this.workspaceObject.metadata.name;
      }
    },
    handleCancel() {
      console.log('取消');
      this.$modal.hide(this.modalName);
    },
    async handleSubmit() {
      if (!this.workspaceObject) return;

      try {
        switch (this.workspaceObject.metadata.type) {
          case WorkspaceObjectType.MemberGroup: {
            const regex = /^[^\s_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/g;
            console.log(this.workspaceObjectName.search(regex));
            if (this.workspaceObjectName.search(regex) !== 0) {
              throw new Error('只允許中文、英文及數字，且至少兩個字。不允許任何空白、特殊符號(~!@#$%^&*()_/)。');
            }

            this.remind = '';

            const updatePaylaod = new UpdateMemberGroupPayload(this.workspaceObjectName);

            const updatedMemberGroupObject = await MemberGroupApi.update(this.workspaceObject.uuid, updatePaylaod);
            // update name and uniqueName
            this.workspaceObject.metadata.name = updatedMemberGroupObject.metadata.name;
            (this.workspaceObject as MemberGroupPreviewModel).uniqueName = updatedMemberGroupObject.uniqueName;

            break;
          }
          case WorkspaceObjectType.Folder: {
            const updatePaylaod = { name: this.workspaceObjectName };
            await FolderApi.update(this.workspaceObject.uuid, updatePaylaod);
            break;
          }
          default:
            throw new Error('unknown object type');
        }

        this.$modal.hide(this.modalName);
        this.$emit('submit');
      } catch (error) {
        // error pop up for user
        // this.remind = error.message;
        this.remind = '只允許中文、英文及數字，且至少兩個字。不允許任何空白、特殊符號(~!@#$%^&*()_/)。';
        this.$notify({
          type: 'error',
          text: '只允許中文、英文及數字，且至少兩個字。不允許任何空白、特殊符號(~!@#$%^&*()_/)。',
        });
      }
    },
  },
});
