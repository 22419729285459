/* eslint-disable max-classes-per-file */
import { WorkspaceObjectModel } from './WorkspaceObjectModel';

export interface MemberModel {
  openEdit: boolean;
  confirmed: boolean;
  uuid: string;
  name: string;
  businessDefinedId: string;
}

export class Member implements MemberModel {
  uuid: string;

  name: string;

  businessDefinedId: string;

  openEdit = false;

  confirmed: boolean;

  constructor(uuid: string, name: string, businessDefinedId: string, confirmed: boolean) {
    this.uuid = uuid;
    this.name = name;
    this.businessDefinedId = businessDefinedId;
    this.confirmed = confirmed;
  }
}

export class UpdateMemberPayload {
  confirmed: boolean;

  constructor(confirmed: boolean) {
    this.confirmed = confirmed;
  }
}

export class UpdateMemberGroupPayload {
  name: string;

  constructor(name: string) {
    this.name = name;
  }
}

export interface MemberGroupModel extends WorkspaceObjectModel {
  uniqueName: string;
  members: MemberModel[];
}

export interface MemberGroupPreviewModel extends WorkspaceObjectModel {
  uniqueName: string;
  memberCount: number;
  previewImageUrl: string;
  autoConfirm?: boolean;
}
