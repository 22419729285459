














































































import Vue from 'vue';
import UpdateWorkspaceObjectNameModal from '@/components/member_group_maker/UpdateWorkspaceObjectNameModal.vue';
import MemberGroupApi from '@/apis/MemberGroupApi';
import {
  MemberModel, UpdateMemberPayload,
} from '@/apis/models/MemberGroupModel';
import Auth from '@/libs/auth';

export default Vue.extend({
  components: { UpdateWorkspaceObjectNameModal },
  data() {
    return {
      members: [] as MemberModel[],
      autoConfirm: false,
    };
  },
  computed: {
    workspaceId(): string {
      return this.$store.state.currentWorkspace.uuid;
    },
    memberGroup(): string {
      return this.$store.state.currentMemberGroup;
    },
    memberGroupId(): string {
      return this.$store.state.currentMemberGroup.uuid;
    },
  },
  mounted() {
    Auth.loginCheck();
    this.loadMemberGroup(this.memberGroupId);
    this.autoConfirm = this.$store.state.autoConfirm;
    // 幫助區
    // this.autoConfirm = this.currentMemberGroup.autoConfirm;
    // -----
  },
  methods: {
    controlAutoConfirm() {
      const result = !this.autoConfirm;
      this.$store.commit('updateAutoConfirm', result);

      // 幫助區 TODO
      this.$store.commit('updateLoading', true);
      // 呼叫更改autoConfirm的Api並儲存
      this.$store.commit('updateLoading', false);
      // -----
    },
    handleUpdateName() {
      this.$modal.show('update-workspace-object-name-modal', {
        workspaceObject: this.$store.state.currentMemberGroup,
      });
    },
    loadMemberGroup(memberGroupId: string) {
      MemberGroupApi.getMemberGroup(memberGroupId).then((memberGroup) => {
        this.members = memberGroup.members;
      });
    },
    editStudent(index: number) {
      const item = this.members[index];
      item.openEdit = true;
    },
    saveEdit(index: number) {
      const item = this.members[index];
      item.openEdit = false;
    },
    toggleConfirmed(index: number) {
      const item = this.members[index];

      const updatePaylaod = new UpdateMemberPayload(!item.confirmed);

      MemberGroupApi.updateMemberInMemberGroup(this.memberGroupId, item.uuid, updatePaylaod).then(() => {
        item.confirmed = !item.confirmed;
      });
    },
    // ---刪除個別學生資料流程---
    openRemoveModal(key: number) {
      this.$modal.show('dialog', {
        title: this.$t('確定刪除嗎?'),
        text: this.$t('這個學生資料刪掉就不會再回來了'),
        buttons: [
          {
            title: this.$t('取消'),
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
          {
            title: this.$t('確定'),
            handler: () => {
              // remove operation
              this.$modal.hide('dialog');
              this.$emit('deleteStudent');
              this.deleteStudent(key);
            },
          },
        ],
      });
    },
    async deleteStudent(index: number) {
      console.log('刪除學生資料');
      if (index >= 0 && index < this.members.length) {
        MemberGroupApi.deleteMemberInMemberGroup(this.memberGroupId, this.members[index].uuid).then(() => {
          this.members.splice(index, 1);
        });
      }
    },
    // --------------
  },
});
