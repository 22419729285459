import i18n from '@/i18n';
import { ProfileModel } from './AccountModel';

export enum WorkspaceObjectType {
  Folder = 'WorkspaceFolder',
  QuizGroup = 'WorkspaceQuizGroup',
  MemberGroup = 'WorkspaceMemberGroup',
  QuizEvent = 'WorkspaceQuizEvent',
  SoftLink = 'WorkspaceSoftLink'
}

export const translate = (type: WorkspaceObjectType): string => {
  switch (type) {
    case WorkspaceObjectType.Folder:
      return i18n.t('資料夾').toString();
    case WorkspaceObjectType.QuizGroup:
      return i18n.t('題組').toString();
    case WorkspaceObjectType.MemberGroup:
      return i18n.t('班級').toString();
    case WorkspaceObjectType.QuizEvent:
      return i18n.t('測驗').toString();
    default:
      return i18n.t('其他文件').toString();
  }
};

export interface WorkspaceObjectModel {
  sourceObject: WorkspaceObjectModel;
  metadata: {
    name: string;
    type: WorkspaceObjectType;
    parentId?: string;
    owner: ProfileModel;
  };
  autoConfirm?: boolean;
  uniqueName: string;
  uuid: string;
  isExamMode?: boolean;
}

export interface WorkspacePathModel {
  uuid: string; // the uuid of a path entry, should be a folder id
  name: string; // the name of a path entry
}

export interface WorkspaceFolderModel extends WorkspaceObjectModel {
  children: WorkspaceObjectModel[];
  path: WorkspacePathModel[];
}

// ----------------------- //
// Request Model           //
// ----------------------- //

export interface UpdateFolderModel {
  name?: string;
}
